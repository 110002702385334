/* eslint-disable global-require */
export default {
    en: {
        welcome: { width: 220, height: 190, image: require('./images/english/welcome.svg') },
        base: { width: 200, height: 132, image: require('./images/english/base.svg') },
        goToGym: { width: 180, height: 171, image: require('./images/english/gym.svg') },
        minigame: { width: 170, height: 188, image: require('./images/english/minigame.svg') },
        arts: { width: 112, height: 145, image: require('./images/english/arts.svg') },
        stem: { width: 112, height: 145, image: require('./images/english/stem.svg') },
        risk: { width: 112, height: 145, image: require('./images/english/risk.svg') },
        il: { width: 112, height: 145, image: require('./images/english/illinois.svg') },
        bullying: { width: 112, height: 145, image: require('./images/english/bullying.svg') },
        done: { width: 170, height: 209, image: require('./images/english/done.svg') },
        legacy: {
            width: 112,
            height: 145,
            image: require('./images/english/legacy.svg'),
        },
        essentialSkills: {
            width: 112,
            height: 145,
            image: require('./images/english/essentialSkills.svg'),
        },
    },

    es: {
        welcome: { width: 220, height: 190, image: require('./images/spanish/welcome.svg') },
        base: { width: 200, height: 132, image: require('./images/spanish/base.svg') },
        goToGym: { width: 180, height: 171, image: require('./images/spanish/gym.svg') },
        minigame: { width: 170, height: 188, image: require('./images/spanish/minigame.svg') },
        arts: { width: 112, height: 145, image: require('./images/spanish/arts.svg') },
        stem: { width: 112, height: 145, image: require('./images/spanish/stem.svg') },
        risk: { width: 112, height: 145, image: require('./images/spanish/risk.svg') },
        il: { width: 112, height: 145, image: require('./images/spanish/illinois.svg') },
        bullying: { width: 112, height: 145, image: require('./images/spanish/bullying.svg') },
        done: { width: 170, height: 209, image: require('./images/spanish/done.svg') },
        legacy: {
            width: 112,
            height: 145,
            image: require('./images/spanish/legacy.svg'),
        },
        essentialSkills: {
            width: 112,
            height: 145,
            image: require('./images/spanish/essentialSkills.svg'),
        },
    },
};
