import React from 'react';
import PropTypes from 'prop-types';
import Map from 'js/components/Map';
import Modal from 'cccisd-modal';
import _capitalize from 'lodash/capitalize';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import classnames from 'classnames';

const Fortress = window.cccisd.fortress;
const AdjustedMap = props => <Map {...props} isPreviewMode />;

export default class Component extends React.Component {
    static propTypes = {
        ageType: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        isPlainSurvey: PropTypes.bool,
    };

    static defaultProps = {
        isPlainSurvey: false,
    };

    render() {
        const { ageType, language, isPlainSurvey } = this.props;
        const surveyType = isPlainSurvey ? 'plain' : 'main';
        const deploymentHandle = `${ageType}-${surveyType}`;
        const title = _capitalize(language) + ' - ' + _capitalize(ageType) + ' - ' + _capitalize(surveyType);

        return (
            <Modal
                title={title}
                trigger={
                    <button
                        id={`demo-${language}-${ageType}`}
                        type="button"
                        className={classnames(
                            'btn',
                            'btn-lg',
                            { 'btn-success': language === 'en' },
                            { 'btn-info': language === 'es' }
                        )}
                        style={{ width: '100%' }}
                    >
                        {_capitalize(ageType)}
                    </button>
                }
                size="large"
            >
                <DeploymentPlayer
                    deploymentHandle={deploymentHandle}
                    disableLayout
                    onComplete={this.onComplete}
                    language={language}
                    allowLanguageChange={false}
                    shouldSaveLanguage={false}
                    shouldSaveFlowList={false}
                    mediator={AdjustedMap}
                    pawnId={Fortress.user.acting.id}
                    pawnHash={Fortress.user.acting.random_hash}
                    isPreviewMode
                />
            </Modal>
        );
    }
}
